import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { Paginated, PaginationQuery } from "../types";
import { buildUrl, mutateCache, useApi } from "./api";
import { CustomError } from "./errors";
import { InsertProjectSharingBody, ProjectSharing, UpdateProjectSharingBody } from "./types/projectSharings";

export const useProjectSharingsApi = () => {
  const api = useApi();

  const updateProjectSharing = useCallback(
    async (projectId: string, userId: string, body: UpdateProjectSharingBody, isAdmin?: boolean): Promise<void> => {
      const url = `${isAdmin ? "/admin" : ""}/projects/${projectId}/sharings/${userId}`;
      await api.put<UpdateProjectSharingBody>(url, body);
      mutateCache([
        `/projects`,
        `/admin/projects`,
        `/projects/${projectId}/sharings`,
        `/admin/projects/${projectId}/sharings`,
      ]);
    },
    [api]
  );

  const insertProjectSharing = useCallback(
    async (projectId: string, body: InsertProjectSharingBody, isAdmin?: boolean): Promise<void> => {
      const url = `${isAdmin ? "/admin" : ""}/projects/${projectId}/sharings`;
      await api.put<InsertProjectSharingBody>(url, body);
      mutateCache([
        `/projects`,
        `/admin/projects`,
        `/projects/${projectId}/sharings`,
        `/admin/projects/${projectId}/sharings`,
      ]);
    },
    [api]
  );

  const useProjectSharings = useCallback(
    (projectId: string, query: PaginationQuery, signal: AbortSignal, isAdmin?: boolean) => {
      const url = buildUrl(`${isAdmin ? "/admin" : ""}/projects/${projectId}/sharings`, query);
      return useSWRImmutable<Paginated<ProjectSharing>, CustomError, string>(url, (url) =>
        api.get<Paginated<ProjectSharing>>({ url, signal })
      );
    },
    [api]
  );

  const deleteProjectSharing = useCallback(
    async (projectId: string, userId: string, isAdmin?: boolean): Promise<void> => {
      await api.del(`${isAdmin ? "/admin" : ""}/projects/${projectId}/sharings/${userId}`).then(() => {
        mutateCache([
          `/projects`,
          `/admin/projects`,
          `/projects/${projectId}/sharings`,
          `/admin/projects/${projectId}/sharings`,
        ]);
      });
    },
    [api]
  );

  return {
    updateProjectSharing,
    insertProjectSharing,
    useProjectSharings,
    deleteProjectSharing,
  };
};
