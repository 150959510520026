import { FC, useContext, useState } from "react";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import useI18n from "../../hooks/useTranslations";
import { isConflict } from "../../http/errors";
import { useDocumentsApi } from "../../http/useDocuments";
import SvgAdd from "../icons/Add";
import Button from "../ui/Button";
import FileUploader from "../ui/FileUploader";
import ModalDialog from "../ui/ModalDialog";

interface AddDocumentButtonProps {
  projectId: string;
  isDisabled?: boolean;
}

const AddDocumentButton: FC<AddDocumentButtonProps> = ({ projectId, isDisabled }: AddDocumentButtonProps) => {
  const i18n = useI18n();
  const [isModalOpen, setModalOpen] = useState(false);
  const [alreadyPresent, setAlreadyPresent] = useState<File>();
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const documentsApi = useDocumentsApi();

  const onSubmit = async (file: File, overwrite?: boolean): Promise<void> => {
    setIsSubmitting(true);

    try {
      await documentsApi.insertProjectDocument(projectId, file, overwrite);
      setModalOpen(false);
      setIsSubmitting(false);
      setAlreadyPresent(undefined);
    } catch (err) {
      if (!overwrite && isConflict(err)) {
        setAlreadyPresent(file);
        return;
      }
      dispatch(showErrorNotification(i18n, err));
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ModalDialog
        title={i18n.translation.documents.add}
        isOpen={isModalOpen && !alreadyPresent}
        onClose={() => {
          setModalOpen(false);
          setIsSubmitting(false);
        }}
      >
        <FileUploader
          onCancel={() => {
            setModalOpen(false);
            setIsSubmitting(false);
          }}
          onSave={onSubmit}
          disabled={isSubmitting}
        />
      </ModalDialog>
      <ModalDialog
        title={i18n.translation.documents.alreadyPresent}
        isOpen={!!alreadyPresent}
        onClose={() => {
          setAlreadyPresent(undefined);
          setIsSubmitting(false);
        }}
        actions={[
          {
            title: i18n.translation.common.cancel,
            isSubmitting: isSubmitting,
            onClick: () => {
              setModalOpen(false);
              setAlreadyPresent(undefined);
            },
          },
          {
            title: i18n.translation.common.overwrite,
            isSubmitting: isSubmitting,
            onClick: () => alreadyPresent && onSubmit(alreadyPresent, true),
          },
        ]}
      >
        {i18n.translation.documents.questions.overwrite}
      </ModalDialog>
      <Button
        glyph={SvgAdd}
        buttonProps={{
          disabled: isDisabled,
          onClick: () => {
            setModalOpen(true);
          },
        }}
      >
        {i18n.translation.documents.add}
      </Button>
    </>
  );
};

export default AddDocumentButton;
