import { FC, useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import { useDebounce } from "../../hooks/useDebounce";
import useInitializer from "../../hooks/useInitializer";
import useListFilters from "../../hooks/useListFilters";
import useI18n from "../../hooks/useTranslations";
import { SearchClient } from "../../http/types/clients";
import { useClientsApi } from "../../http/useClients";
import { PaginationQuery } from "../../types";
import SvgAdd from "../icons/Add";
import Button from "../ui/Button";
import Header from "../ui/Header";
import { ListItemMenuAction } from "../ui/ListItemMenu";
import ModalDialog from "../ui/ModalDialog";
import ScrollContent from "../ui/ScrollContent";
import Table from "../ui/Table";

const AdminClients: FC = () => {
  const i18n = useI18n();
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const [clientToDelete, setClientToDelete] = useState<SearchClient>();
  const dispatch = useContext(NotificationDispatch);
  const clinetsApi = useClientsApi();
  const navigate = useNavigate();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { data, error } = clinetsApi.useClients(query, aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  const rowActions: ListItemMenuAction<SearchClient>[] = [
    {
      getTitle: () => i18n.translation.common.edit,
      onClick: (item) => navigate(`/admin/clients/${item.id}`),
    },
    {
      getTitle: () => i18n.translation.common.delete,
      onClick: (client) => setClientToDelete(client),
    },
  ];

  return (
    <>
      <Header
        title={i18n.translation.clients.plural}
        path={{ display: i18n.translation.clients.all }}
        buttons={
          <Button
            glyph={SvgAdd}
            buttonProps={{
              onClick: () => navigate(`/admin/clients/new`),
            }}
          >
            {i18n.translation.common.createNew}
          </Button>
        }
      />

      <ScrollContent>
        <Table<SearchClient>
          filters={filters}
          isTall={true}
          searchOptions={{
            placeholder: i18n.translation.clients.search,
            onChange: () => {
              aborter.current.abort();
              aborter.current = new AbortController();
            },
          }}
          head={
            <tr>
              <th align="left">{i18n.translation.common.name}</th>
              <th align="left">{i18n.translation.permissions.plural}</th>
              <th align="left">{i18n.translation.secrets.plural}</th>
              <th></th>
            </tr>
          }
          items={data?.items}
          renderRow={(client) => (
            <>
              <td>
                <Link to={client.id}>{client.name}</Link>
              </td>
              <td>{client.permissions}</td>
              <td>{client.secrets}</td>
            </>
          )}
          rowActions={rowActions}
          total={data?.total}
          error={!!error}
        />
        <ModalDialog
          isOpen={!!clientToDelete}
          onClose={() => setClientToDelete(undefined)}
          title={i18n.translation.clients.delete}
          actions={[
            {
              title: i18n.translation.common.cancel,
              onClick: () => setClientToDelete(undefined),
            },
            {
              title: i18n.translation.common.yes,
              onClick: () => {
                clientToDelete &&
                  clinetsApi
                    .deleteClient(clientToDelete.id)
                    .then(() => setClientToDelete(undefined))
                    .catch((err) => dispatch(showErrorNotification(i18n, err)));
              },
            },
          ]}
        >
          {i18n.translation.clients.questions.delete}
        </ModalDialog>
      </ScrollContent>
    </>
  );
};

export default AdminClients;
