import classNames from "classnames";

import { FC, Fragment } from "react";
import useI18n from "../../../hooks/useTranslations";
import { SteelVersionForSettings } from "../../../http/types/vertigrip/vertigripSettings";
import BorderBox from "../../ui/BorderBox";
import ModalDialog from "../../ui/ModalDialog";
import styles from "./SteelVersionsHelpModal.module.css";

type SteelVersionsHelpModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect?: (item: SteelVersionForSettings) => void;
  items: SteelVersionForSettings[];
};

export const SteelVersionsHelpModal: FC<SteelVersionsHelpModalProps> = ({
  items,
  isOpen,
  onClose,
  onSelect,
}: SteelVersionsHelpModalProps) => {
  const i18n = useI18n();

  return (
    <ModalDialog
      title={i18n.translation.steelVersions.plural}
      isOpen={isOpen}
      onClose={onClose}
      actions={[{ title: i18n.translation.common.close, onClick: onClose }]}
      fitContent
    >
      <div
        className={classNames(styles.grid, {
          [styles.grid1]: items.length === 1,
          [styles.grid2]: items.length > 1,
        })}
      >
        {items.map((item) => (
          <Fragment key={item.id}>
            <BorderBox
              onClick={onSelect ? () => onSelect(item) : undefined}
              showIsMissing={true}
              title={item.name}
              description={item.description}
            >
              <div className={styles.img}>
                {item.image ? (
                  <BorderBox.Image src={import.meta.env.VITE_BUCKET_URL + item.image} />
                ) : (
                  BorderBox.PlaceHolder
                )}
              </div>
            </BorderBox>
          </Fragment>
        ))}
      </div>
    </ModalDialog>
  );
};
