import { FC, useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import { useAuth } from "../../context/useAuthContext";
import { useDebounce } from "../../hooks/useDebounce";
import useInitializer from "../../hooks/useInitializer";
import useListFilters from "../../hooks/useListFilters";
import useI18n from "../../hooks/useTranslations";
import { SearchConfiguration } from "../../http/types/configurations";
import { useConfigurationsApi } from "../../http/useConfigurations";
import { PaginationQuery } from "../../types";
import { formatTimeStamp } from "../../utils";
import Header from "../ui/Header";
import { ListItemMenuAction } from "../ui/ListItemMenu";
import ModalDialog from "../ui/ModalDialog";
import ScrollContent from "../ui/ScrollContent";
import Table from "../ui/Table";
import CreateConfigurationButton from "./CreateConfigurationButton";
import DuplicateConfigurationDialog from "./DuplicateConfigurationDialog";

const Configurations: FC = () => {
  const i18n = useI18n();
  const auth = useAuth();
  const dispatch = useContext(NotificationDispatch);
  const navigate = useNavigate();
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const configurationsApi = useConfigurationsApi();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { data, error } = configurationsApi.useConfigurations(query, aborter.current.signal);

  const [configurationToDelete, setConfigurationToDelete] = useState<SearchConfiguration>();
  const [configurationToDuplicate, setConfigurationToDuplicate] = useState<SearchConfiguration>();

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  const rowActions: ListItemMenuAction<SearchConfiguration>[] = [
    {
      getTitle: (item) => (item.canEdit ? i18n.translation.common.edit : i18n.translation.common.open),
      onClick: (item) => navigate(`/configurations/${item.id}`),
    },
    {
      getTitle: () => i18n.translation.common.duplicate,
      onClick: (item) => setConfigurationToDuplicate(item),
    },
    {
      getTitle: () => i18n.translation.common.delete,
      onClick: (item) => {
        item.author.id === auth.identity?.id && setConfigurationToDelete(item);
        return item.author.id !== auth.identity?.id;
      },
      isDisabled: (item) => item.author.id !== auth.identity?.id,
    },
  ];

  return (
    <>
      <Header
        title={i18n.translation.configurations.plural}
        path={{ display: i18n.translation.configurations.my }}
        error={error}
        buttons={
          <CreateConfigurationButton
            type="configurations"
            title={i18n.translation.configurations.createNew}
            disabled={!!error || !data}
          />
        }
      />

      <ScrollContent padding>
        <Table<SearchConfiguration>
          filters={filters}
          isTall={true}
          searchOptions={{
            placeholder: i18n.translation.configurations.search,
            onChange: () => {
              aborter.current.abort();
              aborter.current = new AbortController();
            },
          }}
          head={
            <tr>
              <th align="left">{i18n.translation.common.name}</th>
              <th align="left">{i18n.translation.projects.singular}</th>
              <th align="left">{i18n.translation.systems.singular}</th>
              <th align="left">{i18n.translation.common.author}</th>
              <th align="left">{i18n.translation.common.lastModified}</th>
              <th></th>
            </tr>
          }
          items={data?.items}
          renderRow={(item) => (
            <>
              <td>
                <Link to={`/configurations/${item.id}`}>{item.name}</Link>
              </td>
              <td>{item.project?.name}</td>
              <td>{item.system?.name}</td>
              <td>{item.author?.name}</td>
              <td>{formatTimeStamp(item.modifiedAt)}</td>
            </>
          )}
          rowActions={rowActions}
          total={data?.total}
          error={!!error}
        />
        <ModalDialog
          isOpen={!!configurationToDelete}
          onClose={() => setConfigurationToDelete(undefined)}
          title={i18n.translation.configurations.delete}
          actions={[
            {
              title: i18n.translation.common.cancel,
              onClick: () => setConfigurationToDelete(undefined),
              type: "secondary",
            },
            {
              title: i18n.translation.common.yes,
              onClick: () => {
                configurationToDelete &&
                  configurationsApi
                    .deleteConfiguration(configurationToDelete.project.id, configurationToDelete.id)
                    .then(() => setConfigurationToDelete(undefined))
                    .catch((err) => dispatch(showErrorNotification(i18n, err)));
              },
            },
          ]}
        >
          {i18n.translation.configurations.questions.delete}
        </ModalDialog>
        {!!configurationToDuplicate && (
          <DuplicateConfigurationDialog
            configuration={configurationToDuplicate}
            onClose={() => setConfigurationToDuplicate(undefined)}
            onSave={() => setConfigurationToDuplicate(undefined)}
          />
        )}
      </ScrollContent>
    </>
  );
};

export default Configurations;
