import { FC, useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import { useAuth } from "../../context/useAuthContext";
import useInitializer from "../../hooks/useInitializer";
import useListFilters from "../../hooks/useListFilters";
import useI18n from "../../hooks/useTranslations";
import { SearchConfiguration } from "../../http/types/configurations";
import { useConfigurationsApi } from "../../http/useConfigurations";
import { useProjectsApi } from "../../http/useProjects";
import { formatTimeStamp } from "../../utils";
import SvgExcel from "../icons/Excel";
import Button from "../ui/Button";
import { ListItemMenuAction } from "../ui/ListItemMenu";
import ModalDialog from "../ui/ModalDialog";
import ScrollContent from "../ui/ScrollContent";
import Table from "../ui/Table";
import CreateConfigurationButton from "./CreateConfigurationButton";
import DuplicateConfigurationDialog from "./DuplicateConfigurationDialog";

interface ProjectConfigurationsProps {
  projectId: string;
  isAdmin?: boolean;
  disabled?: boolean;
  canEdit?: boolean;
}

const ProjectConfigurations: FC<ProjectConfigurationsProps> = ({
  projectId,
  isAdmin,
  disabled,
  canEdit,
}: ProjectConfigurationsProps) => {
  const i18n = useI18n();
  const auth = useAuth();
  const projectsApi = useProjectsApi();
  const dispatch = useContext(NotificationDispatch);
  const navigate = useNavigate();
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const configurationsApi = useConfigurationsApi();

  const { data, error } = configurationsApi.useProjectConfigurations(
    projectId,
    filters.state,
    aborter.current.signal,
    isAdmin
  );

  const [configurationToDelete, setConfigurationToDelete] = useState<SearchConfiguration>();
  const [configurationToDuplicate, setConfigurationToDuplicate] = useState<SearchConfiguration>();

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  const rowActions: ListItemMenuAction<SearchConfiguration>[] = [
    {
      getTitle: () => (isAdmin || canEdit ? i18n.translation.common.edit : i18n.translation.common.open),
      onClick: (item) => navigate(`${isAdmin ? "/admin" : ""}/projects/${item.project.id}/configurations/${item.id}`),
    },
    {
      getTitle: () => i18n.translation.common.duplicate,
      onClick: (item) => {
        (canEdit || isAdmin) && setConfigurationToDuplicate(item);
        return !(canEdit || isAdmin);
      },
      isDisabled: () => !isAdmin && !canEdit,
    },
    {
      getTitle: () => i18n.translation.common.delete,
      onClick: (item) => {
        (canEdit || isAdmin) && setConfigurationToDelete(item);
        return !(canEdit || isAdmin);
      },
      isDisabled: () => !isAdmin && !canEdit,
    },
  ];

  return (
    <>
      <ScrollContent padding paddingTop>
        <Table<SearchConfiguration>
          filters={filters}
          isTall={true}
          searchOptions={{
            disabled: disabled,
            placeholder: i18n.translation.configurations.search,
            onChange: () => {
              aborter.current.abort();
              aborter.current = new AbortController();
            },
          }}
          searchButtons={[
            <Button
              glyph={SvgExcel}
              type="secondary"
              buttonProps={{
                disabled: disabled || !!error || !data?.items[0],
                onClick: () =>
                  projectsApi
                    .downloadEServiceExportConfigurationsItemsList(projectId, isAdmin)
                    .catch((err) => dispatch(showErrorNotification(i18n, err))),
              }}
            >
              {i18n.translation.common.excel}
            </Button>,
            <CreateConfigurationButton
              projectId={projectId}
              disabled={disabled || (!isAdmin && !canEdit)}
              type="project"
              title={i18n.translation.configurations.create}
            />,
          ]}
          head={
            <tr>
              <th align="left">{i18n.translation.common.name}</th>
              <th align="left">{i18n.translation.systems.singular}</th>
              <th align="left">{i18n.translation.common.author}</th>
              <th align="left">{i18n.translation.common.lastModified}</th>
              <th></th>
            </tr>
          }
          items={data?.items}
          renderRow={(item) => (
            <>
              <td>
                <Link to={`${isAdmin ? "/admin" : ""}/projects/${item.project.id}/configurations/${item.id}`}>
                  {item.name}
                </Link>
              </td>
              <td>{item.system?.name}</td>
              <td>{item.author?.name}</td>
              <td>{formatTimeStamp(item.modifiedAt, auth.identity?.language)}</td>
            </>
          )}
          rowActions={rowActions}
          total={data?.total}
          error={!!error}
        />
        <ModalDialog
          isOpen={!!configurationToDelete}
          onClose={() => setConfigurationToDelete(undefined)}
          title={i18n.translation.configurations.delete}
          actions={[
            {
              title: i18n.translation.common.cancel,
              onClick: () => setConfigurationToDelete(undefined),
            },
            {
              title: i18n.translation.common.yes,
              onClick: () => {
                configurationToDelete &&
                  configurationsApi
                    .deleteConfiguration(configurationToDelete.project.id, configurationToDelete.id)
                    .then(() => setConfigurationToDelete(undefined))
                    .catch((err) => dispatch(showErrorNotification(i18n, err)));
              },
            },
          ]}
        >
          {i18n.translation.configurations.questions.delete}
        </ModalDialog>
        {!!configurationToDuplicate && (
          <DuplicateConfigurationDialog
            configuration={configurationToDuplicate}
            isAdmin={isAdmin}
            onClose={() => setConfigurationToDuplicate(undefined)}
            onSave={() => setConfigurationToDuplicate(undefined)}
          />
        )}
      </ScrollContent>
    </>
  );
};

export default ProjectConfigurations;
