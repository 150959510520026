import { useCallback } from "react";
import { mutate } from "swr";
import useSWRImmutable from "swr/immutable";
import { Paginated, PaginationQuery } from "../types";
import { buildUrl, mutateCache, useApi } from "./api";
import { CustomError } from "./errors";
import {
  Configuration,
  InsertConfigurationBody,
  SearchAdminConfiguration,
  SearchConfiguration,
  UpdateConfigurationBody,
} from "./types/configurations";

export const useConfigurationsApi = () => {
  const api = useApi();

  const useProjectConfigurations = useCallback(
    (projectId: string, query: PaginationQuery, signal: AbortSignal, isAdmin?: boolean) => {
      const url = buildUrl(`${isAdmin ? "/admin" : ""}/projects/${projectId}/configurations`, query);
      return useSWRImmutable<Paginated<SearchConfiguration>, CustomError, string>(url, (url) =>
        api.get<Paginated<SearchConfiguration>>({ url, signal })
      );
    },
    [api]
  );

  const useConfiguration = useCallback(
    (configurationId: string, signal: AbortSignal) => {
      const url = `/configurations/${configurationId}`;
      return useSWRImmutable<Configuration, CustomError, string>(url, (url) => api.get<Configuration>({ url, signal }));
    },
    [api]
  );

  const useConfigurations = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/configurations`, query);
      return useSWRImmutable<Paginated<SearchConfiguration>, CustomError, string>(url, (url) =>
        api.get<Paginated<SearchConfiguration>>({ url, signal })
      );
    },
    [api]
  );

  const useAdminConfigurations = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/configurations`, query);
      return useSWRImmutable<Paginated<SearchAdminConfiguration>, CustomError, string>(url, (url) =>
        api.get<Paginated<SearchAdminConfiguration>>({ url, signal })
      );
    },
    [api]
  );

  const useAdminConfiguration = useCallback(
    (configurationId: string, signal: AbortSignal) => {
      const url = `/admin/configurations/${configurationId}`;
      return useSWRImmutable<Configuration, CustomError, string>(url, (url) => api.get<Configuration>({ url, signal }));
    },
    [api]
  );

  const insertConfiguration = useCallback(
    async (body: InsertConfigurationBody): Promise<{ id: string }> => {
      const id = await api.post<{ id: string }, InsertConfigurationBody>(`/configurations`, body);
      mutateCache([
        "/projects",
        "/configurations",
        "/admin/projects",
        "/admin/configurations",
        `/projects/${body.projectId}/configurations`,
      ]);
      return id;
    },
    [api]
  );

  const updateConfiguration = useCallback(
    async (
      projectId: string,
      configurationId: string,
      body: UpdateConfigurationBody,
      optimisticData: Configuration
    ): Promise<void> => {
      const url = `/configurations/${configurationId}`;
      api.put<UpdateConfigurationBody>(url, body);
      mutate(url, optimisticData, { revalidate: false, populateCache: true });
      mutateCache(
        [
          "/projects",
          "/configurations",
          "/admin/projects",
          "/admin/configurations",
          `/projects/${projectId}/configurations`,
          `/configurations/${configurationId}/items`,
        ],
        url
      );
    },
    [api]
  );

  const deleteConfiguration = useCallback(
    async (projectId: string, configurationId: string): Promise<void> => {
      await api.del(`/configurations/${configurationId}`).then(() => {
        mutateCache([
          "/projects",
          `/projects/${projectId}/configurations`,
          "/configurations",
          `/configurations/${configurationId}`,
          "/admin/projects",
          "/admin/configurations",
        ]);
      });
    },
    [api]
  );

  const duplicateConfiguration = useCallback(
    async (
      projectId: string,
      configurationId: string,
      body: { projectId: string; name: string },
      isAdmin?: boolean
    ): Promise<void> => {
      await api.post(`${isAdmin ? "/admin" : ""}/configurations/${configurationId}/duplicate`, body).then(() => {
        mutateCache([
          "/projects",
          `/projects/${projectId}/configurations`,
          "/configurations",
          `/configurations/${configurationId}`,
          "/admin/projects",
          `/admin/projects/${projectId}/configurations`,
          "/admin/configurations",
          `/admin/configurations/${configurationId}`,
        ]);
      });
    },
    [api]
  );

  return {
    useProjectConfigurations,
    useConfiguration,
    useAdminConfiguration,
    useConfigurations,
    useAdminConfigurations,
    insertConfiguration,
    updateConfiguration,
    deleteConfiguration,
    duplicateConfiguration,
  };
};
