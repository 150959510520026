import { FC, useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import { useDebounce } from "../../hooks/useDebounce";
import useListFilters from "../../hooks/useListFilters";
import useI18n from "../../hooks/useTranslations";
import { useApi } from "../../http/api";
import { isConflict } from "../../http/errors";
import { SearchDocument } from "../../http/types/documents";
import { useDocumentsApi } from "../../http/useDocuments";
import { PaginationQuery } from "../../types";
import { formatDate } from "../../utils";
import DownloadLink from "../ui/DownloadLink";
import DragAndDrop from "../ui/DragAndDrop";
import ErrorText from "../ui/ErrorText";
import FileImage from "../ui/FileIcon";
import { ListItemMenuAction } from "../ui/ListItemMenu";
import ModalDialog from "../ui/ModalDialog";
import ScrollContent from "../ui/ScrollContent";
import Table from "../ui/Table";
import AddDocumentButton from "./AddDocumentButton";

interface ProjectDocumentsProps {
  isAdmin?: boolean;
  canEdit?: boolean;
}

const ProjectDocuments: FC<ProjectDocumentsProps> = ({ isAdmin, canEdit }: ProjectDocumentsProps) => {
  const i18n = useI18n();
  const api = useApi();
  const documentsApi = useDocumentsApi();

  const { id: projectId } = useParams<{ id: string }>();
  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const [documentToDelete, setDocumentToDelete] = useState<SearchDocument>();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { data, error } = documentsApi.useProjectDocuments(projectId!, query, aborter.current.signal, isAdmin);

  const rowActions: ListItemMenuAction<SearchDocument>[] = [
    {
      getTitle: () => i18n.translation.common.download,
      onClick: (item) =>
        api
          .downloadFile(
            `/files/project_documents/${item.id}/${encodeURIComponent(item.name)}`,
            encodeURIComponent(item.name)
          )
          .catch((err) => dispatch(showErrorNotification(i18n, err))),
    },
    {
      getTitle: () => i18n.translation.common.delete,
      onClick: (item) => {
        (canEdit || isAdmin) && setDocumentToDelete(item);
        return !(canEdit || isAdmin);
      },
      isDisabled: () => !canEdit && !isAdmin,
    },
  ];

  const table = (
    <Table<SearchDocument>
      filters={filters}
      isTall={true}
      searchOptions={{
        placeholder: i18n.translation.documents.search,
        onChange: () => {
          aborter.current.abort();
          aborter.current = new AbortController();
        },
      }}
      searchButtons={<AddDocumentButton projectId={projectId!} isDisabled={!canEdit && !isAdmin}></AddDocumentButton>}
      head={
        <tr>
          <th align="left" style={{ width: 1 }}></th>
          <th align="left">{i18n.translation.common.name}</th>
          <th align="left">{i18n.translation.common.uploadedBy}</th>
          <th align="left">{i18n.translation.common.uploadedAt}</th>
          <th></th>
        </tr>
      }
      items={data?.items}
      renderRow={(item) => (
        <>
          <Table.IconTd>
            <FileImage fileName={item.name} />
          </Table.IconTd>
          <td>
            <DownloadLink
              url={`/files/project_documents/${item.id}/${encodeURIComponent(item.name)}`}
              fileName={encodeURIComponent(item.name)}
            >
              {item.name}
            </DownloadLink>
          </td>
          <td>{item.uploader.name}</td>
          <td>{formatDate(item.uploadedAt)}</td>
        </>
      )}
      rowActions={rowActions}
      total={data?.total}
      error={!!error}
    />
  );

  const [uploadDocumentsResult, setUploadDocumentsResult] = useState<{
    errors: string[];
    conflicts: string[];
  }>();

  const checkFinished = (total: number, result: [number, string][], onDone: () => void) => {
    if (total === result.length) {
      const errors = result.filter((x) => x[0] === 500).map((x) => x[1]);
      const conflicts = result.filter((x) => x[0] === 409).map((x) => x[1]);
      (errors.length || conflicts.length) && setUploadDocumentsResult({ errors, conflicts });
      onDone();
    }
  };

  const onDrop = (files: FileList, onDone: () => void) => {
    const results: [number, string][] = [];
    for (const file of files) {
      projectId &&
        documentsApi
          .insertProjectDocument(projectId, file, false, isAdmin)
          .then(() => {
            results.push([200, file.name]);
            checkFinished(files.length, results, onDone);
          })
          .catch((err) => {
            if (isConflict(err)) {
              results.push([409, file.name]);
              checkFinished(files.length, results, onDone);
              return;
            }
            results.push([500, file.name]);
            checkFinished(files.length, results, onDone);
          });
    }
  };

  return (
    <ScrollContent padding paddingTop>
      <DragAndDrop element={table} onDrop={onDrop} />
      <ModalDialog
        isOpen={!!documentToDelete}
        onClose={() => setDocumentToDelete(undefined)}
        title={i18n.translation.documents.delete}
        actions={[
          {
            title: i18n.translation.common.cancel,
            onClick: () => setDocumentToDelete(undefined),
          },
          {
            title: i18n.translation.common.yes,
            onClick: () => {
              documentToDelete &&
                documentsApi
                  .deleteProjectDocument(projectId!, documentToDelete.id, isAdmin)
                  .then(() => setDocumentToDelete(undefined))
                  .catch((err) => dispatch(showErrorNotification(i18n, err)));
            },
          },
        ]}
      >
        {i18n.translation.documents.questions.delete}
      </ModalDialog>
      <ModalDialog
        isOpen={!!uploadDocumentsResult}
        onClose={() => setUploadDocumentsResult(undefined)}
        title={i18n.translation.common.error}
        actions={[
          {
            title: i18n.translation.common.close,
            onClick: () => setUploadDocumentsResult(undefined),
          },
        ]}
      >
        <ErrorText text={i18n.translation.documents.errors.someCouldNotBeSaved} />
        {!!uploadDocumentsResult?.conflicts.length && (
          <>
            <p>{i18n.translation.common.alreadyPresent}:</p>
            <ul>
              {uploadDocumentsResult?.conflicts.map((x) => (
                <li>{x}</li>
              ))}
            </ul>
          </>
        )}
        {!!uploadDocumentsResult?.errors.length && (
          <>
            <p>{i18n.translation.common.failed}:</p>
            <ul>
              {uploadDocumentsResult?.errors.map((x) => (
                <li>{x}</li>
              ))}
            </ul>
          </>
        )}
      </ModalDialog>
    </ScrollContent>
  );
};

export default ProjectDocuments;
