import { FC, useContext, useEffect } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import { useAuth } from "../../context/useAuthContext";
import useI18n from "../../hooks/useTranslations";
import { useProjectsApi } from "../../http/useProjects";
import { getUILanguages, Language } from "../../types";
import Button from "../ui/Button";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import FormFieldsContainer from "../ui/FormFieldsContainer";
import Input from "../ui/Input";
import reactSelectStyles from "../ui/ReactSelectUtils";
import Textarea from "../ui/Textarea";

interface InsertProjectFormBody {
  name?: string;
  language?: Language;
  description?: string;
}

interface CreateProjectFormProps {
  onSubmit: (projectId: string) => void;
  isSubmitting: boolean;
  setIsSubmitting: (value: boolean) => void;
  closeModal: () => void;
}

const CreateProjectForm: FC<CreateProjectFormProps> = ({
  onSubmit,
  isSubmitting,
  setIsSubmitting,
  closeModal,
}: CreateProjectFormProps) => {
  const i18n = useI18n();
  const auth = useAuth();
  const dispatch = useContext(NotificationDispatch);

  const projectsApi = useProjectsApi();

  const languages = getUILanguages(i18n.translation);

  const submit = (body: InsertProjectFormBody) => {
    setIsSubmitting(true);
    projectsApi
      .insertProject({
        name: body.name!,
        description: body.description,
        language: body.language?.value!,
      })
      .then((res) => onSubmit(res.id))
      .catch((err) => {
        dispatch(showErrorNotification(i18n, err));
        setIsSubmitting(false);
      });
  };

  const defaultValues = {
    name: i18n.translation.projects.new,
    language: languages.find((x) => x.value === auth.identity?.language),
  };

  return (
    <Form<InsertProjectFormBody>
      defaultValues={defaultValues}
      submitText={i18n.translation.common.create}
      secondaryButton={<Button buttonProps={{ onClick: () => closeModal() }}>{i18n.translation.common.cancel}</Button>}
      disabled={isSubmitting}
      onSubmit={() => submit}
    >
      {({ register, formState: { errors }, control, setValue, reset }) => {
        useEffect(() => {
          reset(defaultValues);
        }, [i18n]);

        return (
          <FormFieldsContainer fullWidth>
            <FormField label={i18n.translation.common.name} error={errors.name}>
              {({ labelId, isOptional, isInvalid }) => (
                <Input
                  id={labelId}
                  isInvalid={isInvalid}
                  {...register("name", {
                    required: !isOptional,
                    minLength: 1,
                  })}
                />
              )}
            </FormField>
            <Controller
              name="language"
              control={control}
              rules={{ required: true }}
              defaultValue={defaultValues.language}
              render={({ field, fieldState }) => (
                <FormField label={i18n.translation.common.languages.singular} error={fieldState.error}>
                  {({ labelId }) => (
                    <ReactSelect
                      {...field}
                      inputId={labelId}
                      options={languages}
                      placeholder={`${i18n.translation.common.select}...`}
                      getOptionLabel={(x) => x.display}
                      styles={reactSelectStyles<Language>()}
                      onChange={(value) => setValue("language", value ?? undefined)}
                    />
                  )}
                </FormField>
              )}
            />
            <FormField label={i18n.translation.common.description} error={errors.description} isOptional>
              {({ labelId, isOptional, isInvalid }) => (
                <Textarea id={labelId} isInvalid={isInvalid} {...register("description", { required: !isOptional })} />
              )}
            </FormField>
          </FormFieldsContainer>
        );
      }}
    </Form>
  );
};

export default CreateProjectForm;
