import { useCallback } from "react";
import { mutate } from "swr";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery } from "../types";
import { buildUrl, mutateCache, useApi } from "./api";
import { CustomError } from "./errors";
import { InsertProjectBody, Project, SearchAdminProject, SearchProject, UpdateProjectBody } from "./types/projects";

export const useProjectsApi = () => {
  const api = useApi();

  const useAdminProjects = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl("/admin/projects", query);
      return useSWRImmutable<Paginated<SearchAdminProject>, CustomError, string>(url, () =>
        api.get<Paginated<SearchAdminProject>>({ url, signal })
      );
    },
    [api]
  );

  const useAdminProject = useCallback(
    (id: string, signal: AbortSignal) => {
      return useSWRImmutable<Project, CustomError, string>(`/admin/projects/${id}`, (url) =>
        api.get<Project>({ url, signal })
      );
    },
    [api]
  );

  const useProject = useCallback(
    (id: string, signal: AbortSignal) => {
      return useSWRImmutable<Project, CustomError, string>(`/projects/${id}`, (url) =>
        api.get<Project>({ url, signal })
      );
    },
    [api]
  );

  const getProject = useCallback(
    (id: string, signal: AbortSignal) => {
      return useSWRMutation<Project, CustomError, string>(`/projects/${id}`, (url) =>
        api.get<Project>({ url, signal })
      );
    },
    [api]
  );

  const getMyProjects = useCallback(
    (signal: AbortSignal) => {
      const url = "/projects";
      return useSWRMutation<SearchProject[], CustomError, string>(url, () =>
        api.get<Paginated<SearchProject>>({ url, signal }).then((x) => x.items)
      );
    },
    [api]
  );

  const useMyProjects = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl("/projects", query);
      return useSWRImmutable<Paginated<SearchProject>, CustomError, string>(url, () =>
        api.get<Paginated<SearchProject>>({ url, signal })
      );
    },
    [api]
  );

  const insertProject = useCallback(
    async (body: InsertProjectBody): Promise<{ id: string }> => {
      const url = `/projects`;
      const id = await api.post<{ id: string }, InsertProjectBody>(url, body);
      mutateCache(["/projects", "/admin/projects"]);
      return id;
    },
    [api]
  );

  const updateProject = useCallback(
    async (projectId: string, body: UpdateProjectBody, optimisticData: Project, isAdmin?: boolean): Promise<void> => {
      const url = `${isAdmin ? "/admin" : ""}/projects/${projectId}`;
      await api.put<UpdateProjectBody>(url, body);
      mutate(url, optimisticData, { revalidate: false, populateCache: true });
      mutateCache(["/projects", "/admin/projects"], url);
    },
    [api]
  );

  const mutateMyProjects = useCallback(
    (query: PaginationQuery, optimisticData: Paginated<SearchProject>) => {
      const url = buildUrl("/projects", query);
      mutate(url, optimisticData, { revalidate: false, optimisticData: true });
    },
    [api]
  );

  const deleteProject = useCallback(
    async (projectId: string, isAdmin?: boolean): Promise<void> => {
      await api.del(`${isAdmin ? "/admin" : ""}/projects/${projectId}`);
      mutateCache(["/projects", `/projects/${projectId}`, "/admin/projects", `/admin/projects/${projectId}`]);
    },
    [api]
  );

  const updateProjectStatus = useCallback(
    async (projectId: string, status: string, isAdmin?: boolean): Promise<void> => {
      const url = `${isAdmin ? "/admin" : ""}/projects/${projectId}/status`;
      await api.put<{ status: string }>(url, { status });
      mutateCache([`/projects/${projectId}`, `/admin/projects/${projectId}`]);
    },
    [api]
  );

  const downloadEServiceExportConfigurationsItemsList = useCallback(
    (projectId: string, isAdmin?: boolean) => {
      return api.downloadExcel(`${isAdmin ? "/admin" : ""}/projects/${projectId}/items`, "E-Service import.xlsx");
    },
    [api]
  );

  return {
    useAdminProjects,
    useAdminProject,
    getProject,
    useProject,
    getMyProjects,
    useMyProjects,
    insertProject,
    updateProject,
    mutateMyProjects,
    deleteProject,
    updateProjectStatus,
    downloadEServiceExportConfigurationsItemsList,
  };
};
