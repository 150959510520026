import { FC, useContext, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import useInitializer from "../../hooks/useInitializer";
import useI18n from "../../hooks/useTranslations";
import { useConfigurationsApi } from "../../http/useConfigurations";
import { useProjectsApi } from "../../http/useProjects";
import Button from "../ui/Button";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import FormFieldsContainer from "../ui/FormFieldsContainer";
import Input from "../ui/Input";
import ModalDialog from "../ui/ModalDialog";
import reactSelectStyles from "../ui/ReactSelectUtils";

interface DuplicateConfigurationDialogForm {
  project: {
    id: string;
    name: string;
  };
  name: string;
}

interface DuplicateConfigurationDialogProps {
  configuration: {
    id: string;
    name: string;
    project: {
      id: string;
      name: string;
    };
  };
  onClose: () => void;
  onSave: () => void;
  isAdmin?: boolean;
}

const DuplicateConfigurationDialog: FC<DuplicateConfigurationDialogProps> = ({
  configuration,
  onClose,
  onSave,
  isAdmin,
}: DuplicateConfigurationDialogProps) => {
  const i18n = useI18n();
  const projectsApi = useProjectsApi();
  const dispatch = useContext(NotificationDispatch);
  const aborter = useRef(new AbortController());
  const configurationsApi = useConfigurationsApi();

  const { data, error, trigger } = projectsApi.getMyProjects(aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  useEffect(() => {
    trigger();
  }, []);

  if (!data && !error && !isInitialized) {
    return;
  }

  const editableProjects = data?.filter((x) => x.canEdit);
  const defaultValues: Partial<DuplicateConfigurationDialogForm> = {
    project: editableProjects?.find((x) => x.id === configuration.project.id) ?? editableProjects?.[0],
    name: configuration.name + ` (${i18n.translation.common.copy})`,
  };

  return (
    <ModalDialog isOpen={true} onClose={() => onClose()} title={i18n.translation.configurations.duplicate}>
      <Form<DuplicateConfigurationDialogForm>
        defaultValues={defaultValues}
        onSubmit={() => (formData) => {
          configurationsApi
            .duplicateConfiguration(
              configuration.project.id,
              configuration.id,
              {
                projectId: formData.project.id,
                name: formData.name,
              },
              isAdmin
            )
            .then(() => onSave())
            .catch((err) => dispatch(showErrorNotification(i18n, err)));
        }}
        submitText={i18n.translation.common.ok}
        secondaryButton={<Button buttonProps={{ onClick: () => onClose() }}>{i18n.translation.common.cancel}</Button>}
      >
        {({ register, formState: { errors }, control, reset }) => {
          useEffect(() => {
            reset(defaultValues);
          }, [configuration.id]);

          return (
            <FormFieldsContainer fullWidth>
              <Controller
                name="project"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <FormField label={i18n.translation.common.selectProjectInwhichCreateCopy} error={fieldState.error}>
                    {({ labelId }) => (
                      <Select
                        {...field}
                        inputId={labelId}
                        options={data?.filter((x) => x.canEdit)}
                        placeholder={`${i18n.translation.common.select}...`}
                        // isDisabled={(!initialProjectId && isLoadingProjects) || isSubmitting}
                        // isLoading={(!initialProjectId && isLoadingProjects) || isSubmitting}
                        getOptionValue={(x) => x.id}
                        getOptionLabel={(x) => x.name}
                        styles={reactSelectStyles<{ id: string; name: string }>()}
                        // onChange={(selectedOption) => setValue("project", selectedOption ?? null)}
                        menuPosition="fixed"
                      />
                    )}
                  </FormField>
                )}
              />
              <FormField label={i18n.translation.common.newConfigurationName} error={errors.name}>
                {({ labelId, isOptional, isInvalid }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    {...register("name", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
            </FormFieldsContainer>
          );
        }}
      </Form>
    </ModalDialog>
  );
};

export default DuplicateConfigurationDialog;
