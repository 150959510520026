import { GoogleMap, Libraries, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

import { FC, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { useAuth } from "../../context/useAuthContext";
import useI18n from "../../hooks/useTranslations";
import { getProjectStatusValues, Project, ProjectStatus } from "../../http/types/projects";
import { getUILanguages, Language } from "../../types";
import SimpleTextModalDialog from "../configurations/vertigrip/SimpleTextModalDialog";
import ErrorText from "../ui/ErrorText";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import Input from "../ui/Input";
import reactSelectStyles from "../ui/ReactSelectUtils";
import Textarea from "../ui/Textarea";
import styles from "./ProjectDetails.module.css";

interface ProjectDetailsProps {
  project?: Project;
  onSubmit: (body: ProjectDetailsFormBody) => Promise<void>;
  disabled?: boolean;
  isLoading?: boolean;
  isAdmin?: boolean;
  canEdit?: boolean;
}

export interface ProjectDetailsFormBody {
  name?: string;
  language?: Language;
  status?: ProjectStatus;
  description?: string;
  address?: string;
  city?: string;
  country?: string;
  coordinates?: string;
  company?: string;
  contactPerson?: string;
}
const zoomNoCoordinates = 16;
const zoomCoordinates = 18;

const libraries: Libraries = ["places"];

const ProjectDetails: FC<ProjectDetailsProps> = ({
  project,
  onSubmit,
  disabled,
  isLoading,
  isAdmin,
  canEdit,
}: ProjectDetailsProps) => {
  const i18n = useI18n();
  const auth = useAuth();
  const searchBox = useRef<google.maps.places.SearchBox | null>(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const coordinates = useRef<string | undefined>(project?.coordinates);
  const [mapError, setMapError] = useState<boolean>(false);
  const map = useRef<google.maps.Map>();
  const geocoder = useRef<google.maps.Geocoder>();
  const currentMarker = useRef<google.maps.marker.AdvancedMarkerElement>();
  const [showContactPersonDialog, setShowContactPersonDialog] = useState(false);

  const languages = getUILanguages(i18n.translation);

  const stati = getProjectStatusValues(i18n.translation);

  const moveCamera = (mapInstance: google.maps.Map, coordinates: string) => {
    const latLng = new google.maps.LatLng({
      lat: Number(coordinates.split(",")[0]),
      lng: Number(coordinates.split(",")[1]),
    });
    mapInstance.moveCamera({
      center: latLng,
    });

    updateMarker(latLng);
  };

  const onMapLoad = (mapInstance: google.maps.Map) => {
    map.current = mapInstance;
    geocoder.current = new google.maps.Geocoder();
  };

  const onDblClick = useRef<(latLng: google.maps.LatLng) => void>();

  const { isLoaded: isMapLoaded, loadError: mapLoadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
    language: auth.identity?.language,
  });

  useEffect(() => {
    setMapError(!!mapLoadError);
    setIsPageLoading(!isMapLoaded);
  }, [mapLoadError, isMapLoaded]);

  const updateMarker = async (latLng?: google.maps.LatLng) => {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;
    if (currentMarker.current) {
      currentMarker.current.map = undefined;
    }

    if (latLng) {
      currentMarker.current = new AdvancedMarkerElement({
        map: map.current,
        position: latLng,
      });
    }
  };

  const defaultValues: ProjectDetailsFormBody = {
    name: project?.name,
    address: project?.address,
    city: project?.city,
    country: project?.country,
    description: project?.description,
    language: languages.find((x) => x.value === project?.language),
    status: stati.find((x) => x.value === project?.status),
    company: project?.company,
    contactPerson: project?.contactPerson,
    coordinates: project?.coordinates,
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <Form<ProjectDetailsFormBody>
          defaultValues={defaultValues}
          mode="onBlur"
          disabled={disabled}
          // the onSubmit is only triggered by the button, which is visible only to admins
          onSubmit={isAdmin ? () => (formData) => onSubmit(formData) : undefined}
          submitText={i18n.translation.common.save}
        >
          {({ register, reset, formState: { errors }, control, setValue, trigger, handleSubmit }) => {
            useEffect(() => {
              project && reset(defaultValues);
            }, [project]);

            useEffect(() => {
              if (isMapLoaded && map.current) {
                defaultValues?.coordinates
                  ? moveCamera(map.current, defaultValues.coordinates)
                  : navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
                      map.current?.moveCamera({
                        center: new google.maps.LatLng({
                          lat,
                          lng,
                        }),
                      });
                    });
              }
            }, [defaultValues, isMapLoaded]);

            // this is the automatic save mechanism, active only for the non admin page, only if the user is allowed to edit
            const submit = () => {
              !isAdmin &&
                canEdit &&
                trigger().then((x) => {
                  x &&
                    handleSubmit((data) => {
                      onSubmit(data).then(() => {
                        reset(data);
                      });
                    })();
                });
            };

            const getAddress = (results: google.maps.GeocoderResult[] | google.maps.places.PlaceResult[]) => {
              const address = results[0]?.formatted_address;

              let city = null;
              let province = null;

              const components = results[0]?.address_components ?? [];

              const countryShort = components.find((x) => x.types.includes("country"))?.short_name;

              // Attempt to retrieve the postal_town for UK and Northern Ireland first
              const postalTown = components.find((x) => x.types.includes("postal_town"))?.long_name;

              if (postalTown) {
                // For UK and Northern Ireland, use postal_town as the city
                city = postalTown;
              } else {
                // Default pattern for other regions (EU, etc.)
                city = components.find((x) => x.types.includes("locality"))?.long_name;

                // Fallback to administrative_area_level_3 if city is not found
                if (!city) {
                  city = components.find((x) => x.types.includes("administrative_area_level_3"))?.long_name;
                }

                // Fallback to administrative_area_level_2 if city is still not found
                if (!city) {
                  city = components.find((x) => x.types.includes("administrative_area_level_2"))?.long_name;
                }

                // Fallback to administrative_area_level_1 if city is still not found
                if (!city) {
                  city = components.find((x) => x.types.includes("administrative_area_level_1"))?.long_name;
                }

                if (countryShort === "IT") {
                  province = components.find((x) => x.types.includes("administrative_area_level_2"))?.short_name;
                } else {
                  const aal1 =
                    components.find((x) => x.types.includes("administrative_area_level_1"))?.short_name ?? "";
                  const aal2 =
                    components.find((x) => x.types.includes("administrative_area_level_2"))?.short_name ?? "";
                  if (aal1 !== aal2) {
                    province = aal1?.length < aal2?.length ? aal1 : aal2;
                  }
                }
              }
              const cityProvince = province && city !== province ? `${city} (${province})` : city;

              const country = results[0]?.address_components?.find((x) => x.types.includes("country"))?.long_name;

              return {
                address,
                cityProvince,
                province,
                country,
              };
            };

            onDblClick.current = (latLng: google.maps.LatLng) => {
              const coords = `${latLng.lat().toFixed(7)},${latLng.lng().toFixed(7)}`;
              if (geocoder.current) {
                const timeout = setTimeout(() => {
                  setIsPageLoading(true);
                }, 200);
                geocoder.current.geocode({ location: latLng }, (results, status) => {
                  if (status === google.maps.GeocoderStatus.OK && results) {
                    const address = getAddress(results);

                    setValue("city", address.cityProvince);
                    setValue("country", address.country);
                    setValue("address", address.address);
                    setValue("coordinates", coords);
                    updateMarker(latLng);
                  }

                  clearTimeout(timeout);
                  setIsPageLoading(false);
                  setMapError(
                    status !== google.maps.GeocoderStatus.OK && status !== google.maps.GeocoderStatus.ZERO_RESULTS
                  );
                  submit();
                });
              }
            };

            return (
              <>
                <FormField label={i18n.translation.common.name} error={errors.name}>
                  {({ labelId, isOptional, isInvalid }) => (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || isLoading}
                      readOnly={!isAdmin && !canEdit}
                      {...register("name", {
                        required: !isOptional,
                        minLength: 1,
                      })}
                      onBlur={(e) => {
                        setValue("name", e.target.value);
                        defaultValues.name !== e.target.value && submit();
                      }}
                    />
                  )}
                </FormField>
                <Controller
                  name="language"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={defaultValues.language}
                  render={({ field, fieldState }) => (
                    <FormField label={i18n.translation.common.languages.singular} error={fieldState.error}>
                      {({ labelId }) => (
                        <ReactSelect
                          {...field}
                          inputId={labelId}
                          options={languages}
                          placeholder={`${i18n.translation.common.select}...`}
                          isDisabled={!isMapLoaded || disabled || isLoading || (!isAdmin && !canEdit)}
                          isLoading={!isMapLoaded || isLoading}
                          getOptionLabel={(x) => x.display}
                          styles={reactSelectStyles<Language>()}
                          onChange={(value) => {
                            setValue("language", value ?? undefined);
                            defaultValues.language?.value !== value?.value && submit();
                          }}
                        />
                      )}
                    </FormField>
                  )}
                />
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={defaultValues.status}
                  render={({ field, fieldState }) => (
                    <FormField label={i18n.translation.common.status} error={fieldState.error}>
                      {({ labelId }) => (
                        <ReactSelect
                          {...field}
                          inputId={labelId}
                          options={stati}
                          placeholder={`${i18n.translation.common.select}...`}
                          isDisabled={!isMapLoaded || disabled || isLoading || (!isAdmin && !canEdit)}
                          isLoading={!isMapLoaded || isLoading}
                          getOptionLabel={(x) => x.display}
                          styles={reactSelectStyles<ProjectStatus>()}
                          onChange={(value) => {
                            setValue("status", value ?? undefined);
                            defaultValues.language?.value !== value?.value && submit();
                          }}
                        />
                      )}
                    </FormField>
                  )}
                />
                <FormField
                  label={i18n.translation.common.address}
                  error={errors.address}
                  isOptional
                  showHelp={{
                    type: "error",
                    onOpen: () => setShowContactPersonDialog(true),
                  }}
                >
                  {({ labelId, isOptional, isInvalid }) => {
                    const input = (
                      <Input
                        id={labelId}
                        placeholder=""
                        isInvalid={isInvalid}
                        disabled={disabled || isLoading}
                        readOnly={!isAdmin && !canEdit}
                        {...register("address", {
                          required: !isOptional,
                        })}
                        onBlur={(e) => {
                          if (!e.target.value) {
                            updateMarker();
                            setValue("city", undefined);
                            setValue("country", undefined);
                            setValue("coordinates", undefined);
                          }
                          setValue("address", e.target.value);
                          defaultValues?.address !== e.target.value && submit();
                        }}
                      />
                    );

                    if (!canEdit && !isAdmin) {
                      return input;
                    }

                    return isMapLoaded ? (
                      <div className={styles.standAloneSearchBox}>
                        <StandaloneSearchBox
                          onLoad={(ref) => (searchBox.current = ref)}
                          onPlacesChanged={() => {
                            const places = searchBox.current?.getPlaces();
                            if (places && places.length > 0) {
                              const place = places[0];
                              const location = place?.geometry?.location;
                              if (location) {
                                const coords = `${location?.lat().toFixed(7)},${location?.lng().toFixed(7)}`;
                                map.current?.moveCamera({ center: location });
                                updateMarker(location);

                                const address = getAddress(places);

                                setValue("city", address.cityProvince);
                                setValue("country", address.country);
                                setValue("address", address.address);
                                setValue("coordinates", coords);
                              }

                              submit();
                            }
                          }}
                        >
                          {input}
                        </StandaloneSearchBox>
                      </div>
                    ) : (
                      <Input id={labelId} isInvalid={isInvalid} disabled />
                    );
                  }}
                </FormField>
                <div className={styles.cityCountry}>
                  <FormField label={i18n.translation.common.city} error={errors.city} isOptional>
                    {({ labelId, isOptional, isInvalid }) => (
                      <Input
                        id={labelId}
                        placeholder=""
                        isInvalid={isInvalid}
                        disabled={disabled || isLoading}
                        readOnly={!isAdmin && !canEdit}
                        {...register("city", {
                          required: !isOptional,
                        })}
                        onBlur={(e) => {
                          setValue("city", e.target.value);
                          defaultValues?.city !== e.target.value && submit();
                        }}
                      />
                    )}
                  </FormField>
                  <FormField label={i18n.translation.common.country} error={errors.country} isOptional>
                    {({ labelId, isOptional, isInvalid }) => (
                      <Input
                        id={labelId}
                        placeholder=""
                        isInvalid={isInvalid}
                        disabled={disabled || isLoading}
                        readOnly={!isAdmin && !canEdit}
                        {...register("country", {
                          required: !isOptional,
                        })}
                        onBlur={(e) => {
                          setValue("country", e.target.value);
                          defaultValues?.country !== e.target.value && submit();
                        }}
                      />
                    )}
                  </FormField>
                </div>
                <FormField label={i18n.translation.common.company} error={errors.company} isOptional>
                  {({ labelId, isOptional, isInvalid }) => (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || isLoading}
                      readOnly={!isAdmin && !canEdit}
                      {...register("company", {
                        required: !isOptional,
                        minLength: 1,
                      })}
                      onBlur={(e) => {
                        setValue("company", e.target.value);
                        defaultValues.company !== e.target.value && submit();
                      }}
                    />
                  )}
                </FormField>
                <FormField
                  label={i18n.translation.common.contactPerson}
                  error={errors.contactPerson}
                  isOptional
                  showHelp={{
                    type: "error",
                    onOpen: () => setShowContactPersonDialog(true),
                  }}
                >
                  {({ labelId, isOptional, isInvalid }) => (
                    <>
                      <Input
                        id={labelId}
                        isInvalid={isInvalid}
                        disabled={disabled || isLoading}
                        readOnly={!isAdmin && !canEdit}
                        {...register("contactPerson", {
                          required: !isOptional,
                          minLength: 1,
                        })}
                        onBlur={(e) => {
                          setValue("contactPerson", e.target.value);
                          defaultValues.contactPerson !== e.target.value && submit();
                        }}
                      />
                      <SimpleTextModalDialog
                        title={i18n.translation.common.contactPerson}
                        text={{ value: i18n.translation.common.contactPersonWarning }}
                        isOpen={showContactPersonDialog}
                        onClose={() => setShowContactPersonDialog(false)}
                      />
                    </>
                  )}
                </FormField>
                <FormField label={i18n.translation.common.description} error={errors.description} isOptional>
                  {({ labelId, isOptional, isInvalid }) => (
                    <Textarea
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || isLoading || (!isAdmin && !canEdit)}
                      rows={5}
                      {...register("description", { required: !isOptional })}
                      onBlur={(e) => {
                        setValue("description", e.target.value);
                        defaultValues.description !== e.target.value && submit();
                      }}
                    />
                  )}
                </FormField>
              </>
            );
          }}
        </Form>
      </div>
      <div className={styles.gmapsContainer}>
        {(isPageLoading || mapError || disabled || isLoading) && (
          <div className={styles.gmapsOverlay}>
            {isPageLoading && <div className={styles.spinner}></div>}
            {mapError && <ErrorText text={i18n.translation.common.error} />}
          </div>
        )}
        {isMapLoaded && !mapError && (
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100%",
              outline: "none",
            }}
            zoom={coordinates ? zoomCoordinates : zoomNoCoordinates}
            onLoad={onMapLoad}
            options={{
              disableDoubleClickZoom: true,
              mapTypeId: "satellite",
              mapId: "PROJECT_MAP_ID",
            }}
            onDblClick={(e) => {
              if (isAdmin || canEdit) {
                e.stop();
                if (e.latLng) {
                  onDblClick.current?.(e.latLng);
                }
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;
